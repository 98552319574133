






























import { vxm } from "@/store";
import { Component, Vue } from "vue-property-decorator";
import AssetCard from "@/views/widgets/AssetCard.vue";

import WaitModal from "@/views/widgets/modals/WaitModal.vue";
import CustomCard from "@/views/viewcomponents/CustomCard.vue";
import {Configuration, ProfileApiFactory} from "@shared_vue/openapi/myvaultapi";

@Component({
  components: { AssetCard, WaitModal, CustomCard },
})
export default class MyAssetsPage extends Vue {
  private ui = vxm.ui;
  private profileStore = vxm.profile;
  private profileApi = ProfileApiFactory(<Configuration>{basePath:process.env.VUE_APP_MYVAULT_API_URL},process.env.VUE_APP_MYVAULT_API_URL);
  private showWait: boolean = true;

  mounted(){
    this.showWait=true;
    let outerThis = this;
    this.profileApi.profileMyAssets().then(resp => {          
      console.log(resp.data);
      //argh we need a store for all this profile data
      this.profileStore.setAssets(resp.data);
    })
        .catch(err => {
          // Handle Error Here
          console.error(err);
        }).finally(()=>{
      outerThis.showWait=false;
    });
  }
  //note, I am doing it this way so that it is easy to change from the DB in theory (without redeploying the frontend
  //then obviously this cards array must be fetched by webservice
  //also this makes localisation much easier with translations and so on being returned from the server according
  //to logged in user
  private cards = [
    {
      id: 1,
      title: 'My Pets',
      description: 'some description',
      image: 'star.png',
      link: '/dashboard/myasset/Pets',
      buttonText: 'Manage/Set up'
    },
    {
      id: 2,
      title: 'LiveStock',
      description: 'some description',
      image: 'star.png',
      link: '/dashboard/myasset/Livestock',
      buttonText: 'Manage/Set up'
    },
    {
      id: 3,
      title: 'Property',
      description: 'some description',
      image: 'star.png',
      link: '/dashboard/myasset/Property',
      buttonText: 'Manage/Set up'
    },
    {
      id: 4,
      title: 'Household & Personal',
      description: 'some description',
      image: 'star.png',
      link: '/dashboard/myasset/Household',
      buttonText: 'Manage/Set up'
    },
    {
      id: 5,
      title: 'Electronics & Devices',
      description: 'some description',
      image: 'star.png',
      link: '/dashboard/myasset/Electronics',
      buttonText: 'Manage/Set up'
    },
    {
      id: 6,
      title: 'Vehicles',
      description: 'some description',
      image: 'star.png',
      link: '/dashboard/myasset/Vehicles',
      buttonText: 'Manage/Set up'
    },
    {
      id: 7,
      title: 'Firearms & Competencies',
      description: 'some description',
      image: 'star.png',
      link: '/dashboard/myasset/Firearms',
      buttonText: 'Manage/Set up'
    }
  ]
}
